import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInsightsContainer } from 'components/insights/context';
import { getUserMetricsConfiguration } from 'app/modules/insights/modules/pagePerformance/utils/userMetricsConfiguration';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  getMetricsProperty,
  IG_METRICS_PROPERTIES,
} from 'app/modules/insights/modules/pagePerformance/utils/metricProperty';
import PageMetricCard from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCard';
import { Page } from 'app/modules/insights/types/Page';
import SimpleGrid from 'app/components/SimpleGrid';
import { OrderedMetricCard } from 'app/modules/insights/modules/pagePerformance/types/graphs';

type Props = {
  page: Page;
  selectedMetrics?: string[];
  chartWidth?: number | string;
};

const PagePerformancePdfExportMetricChartsCards: FC<Props> = ({
  page,
  selectedMetrics,
  chartWidth,
}) => {
  const { t } = useTranslation();
  const { userPageConfiguration } = useInsightsContainer();

  const initDefaultMetrics = useCallback(() => {
    return getUserMetricsConfiguration(
      page.id,
      page._type,
      userPageConfiguration,
    );
  }, [page.id, page._type, userPageConfiguration]);

  const [metrics, setMetrics] =
    useState<OrderedMetricCard[]>(initDefaultMetrics);

  useEffect(() => {
    setMetrics(initDefaultMetrics());
  }, [page.id, initDefaultMetrics]);

  const groupedMetrics = useMemo(() => {
    const grouped = [];
    if (page._type === TYPE.FACEBOOK) {
      grouped.push({
        title: 'pagePerformance.pageEngagement',
        metrics: metrics.slice(0, 5),
      });
      grouped.push({
        title: 'pagePerformance.pageImpressions',
        metrics: metrics.slice(5),
      });
    } else {
      grouped.push({
        title: 'pagePerformance.overview',
        metrics: metrics,
      });
    }
    return grouped;
  }, [metrics, page._type]);

  const getDefaultTab = (metricKey: string) => {
    if (metricKey === IG_METRICS_PROPERTIES.followerDemographics.key) {
      return 'follower_demographics_country';
    }

    if (metricKey === IG_METRICS_PROPERTIES.reachedAudience.key) {
      return 'reached_audience_country';
    }

    return undefined;
  };

  return (
    <div className="tw-w-full">
      {groupedMetrics.map((group, index) => (
        <div key={index} style={{ breakInside: 'avoid' }}>
          <div className="tw-mb-6 tw-w-full">
            <div className="tw-mb-6">
              <div className="tw-text-base tw-font-semibold">
                {t(group.title)}
              </div>
            </div>
            <div className="tw-block">
              <SimpleGrid
                columns={2}
                gap={0}
                style={{ rowGap: 12, columnGap: 12 }}
              >
                {group.metrics
                  .filter(
                    (metric) =>
                      selectedMetrics?.includes(metric.metricKey) ?? true,
                  )
                  .map((metric, index) => {
                    const cardMetric = getMetricsProperty(
                      page._type,
                      metric.metricKey,
                    );

                    if (!cardMetric) return <></>;

                    return (
                      <div
                        style={{ breakInside: 'avoid' }}
                        key={metric.metricKey}
                      >
                        <PageMetricCard
                          showTabs={false}
                          key={metric.metricKey}
                          page={page}
                          metric={cardMetric}
                          expanded={false}
                          onToggleExpandClick={() => null}
                          chartWidth={chartWidth}
                          chartHeight={208}
                          defaultTab={getDefaultTab(metric.metricKey)}
                        />
                      </div>
                    );
                  })}
              </SimpleGrid>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PagePerformancePdfExportMetricChartsCards;
